import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { InfoCardFlexModuleResult, InfoCardsProps } from "./typings";
import { DashboardHighlights } from "@shared-ui/travel-agent-dashboard-highlights";

export const InfoCards = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: InfoCardsProps) => {
    const { templateComponent, flexModuleModelStore } = props;
    const {
      metadata: { id },
    } = templateComponent;
    const model = flexModuleModelStore.getModel(id) as InfoCardFlexModuleResult | null;

    /* istanbul ignore if */
    if (!model) {
      return null;
    }

    /* istanbul ignore if */
    if (!flexModuleModelStore) {
      return null;
    }

    const { dashboardType } = model as any;

    return (
      <div id={id} data-testid="info-card">
        <DashboardHighlights inputs={{ dashboardType }} />
      </div>
    );
  })
);

export default InfoCards;
